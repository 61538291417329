<template>
  <div class="picture-config">
    <x-table
      :no-data-text="CA('pictureConfig_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @selCb="selCb"
    ></x-table>

    <Modal v-model="modal.show" :title="modal.title" @on-visible-change="modalShow">
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="图片名称" prop="title">
          <Input v-model="form.title"></Input>
        </FormItem>
        <FormItem label="跳转地址" prop="url">
          <Input v-model="form.url"></Input>
        </FormItem>
        <FormItem label="项目类型" prop="type">
          <Select v-model="form.type" @on-change="getImgCategory">
            <Option
              v-for="item in projectCategory"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="图片类型" prop="imgType">
          <Select v-model="form.imgType">
            <Option
              v-for="item in imgCategory"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="是否可点击" prop="isClick">
          <RadioGroup v-model="form.isClick">
            <Radio label="1">是</Radio>
            <Radio label="0">否</Radio>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="图片文件" prop="icon">
          <upload-image v-model="form.icon" :max="1" :clearFlag="!modal.show"></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align:center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit">提交</Button>
        <Button @click="() => modal.show = false">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "图片名称",
            minWidth: 100,
            key: "title",
          },
          {
            title: "图片",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <div style="display:flex;flex-wrap:wrap">
                  {row.icon.split(",").map((item) => {
                    return (
                      <img
                        src={this.getImgUrl(row.servicePath, item)}
                        style="width:35px;height:35px;margin-right:10px"
                      />
                    );
                  })}
                </div>
              );
            },
          },
          {
            title: "项目类型",
            minWidth: 100,
            key: "typeName",
          },
          {
            title: "跳转地址",
            minWidth: 100,
            key: "url",
          },
          {
            title: "图片类型",
            minWidth: 100,
            key: "imgTypeName",
          },
          {
            title: "是否可点击",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.isClick == 0 ? "否" : "是"}</span>;
            },
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("pictureConfig_edit") && (
                    <a style="margin-right:10px" onClick={() => this.edit(row)}>
                      编辑
                    </a>
                  )}
                  {this.CA("pictureConfig_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        title: "",
        url: "",
        type: "",
        imgType: "",
        isClick: "0",
        icon: "",
      },
      rules: {
        title: [{ required: true, message: "请填写图片名称" }],
        url: [{ required: true, message: "请填写跳转地址" }],
        type: [{ required: true, message: "请选择项目类型" }],
        imgType: [{ required: true, message: "请选择图片类型" }],
        icon: [{ required: true, message: "请上传图片", trigger: "blur" }],
      },

      projectCategory: [],
      imgCategory: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增图片配置",
            ca: "pictureConfig_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "项目类型",
              component: "select",
              field: "type",
              data: this.projectCategory,
              defaultValue: "",
              parameterField: "id",
              showField: "name",
              isCallback: true,
              mark: "1",
            },
            {
              conditionName: "图片类型",
              component: "select",
              field: "imgType",
              data: this.imgCategory,
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
          ],
        },
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增图片配置",
        submitLoading: false,
      };
    },
    search(data) {
      this.search_data = data;
      this.getList();
    },
    selCb(val, mark) {
      if (mark == "1") {
        this.getImgCategory(val);
        if (!val) {
          this.imgCategory = [];
        }
      }
    },
    getList() {
      if (!this.CA("pictureConfig_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PICTURE_CONFIG.LIST, {
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getProjectType() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "175",
      }).then((res) => {
        this.projectCategory = res.list;
      });
    },
    getImgCategory(pid) {
      if (!pid) return;
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid,
      }).then((res) => {
        this.imgCategory = res.list;
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.type = row.type + "";
      this.form.imgType = row.imgType + "";
      this.getImgCategory(this.form.type);
      this.form.icon = row.icon
        .split(",")
        .map((item) => this.getImgUrl(row.servicePath, item))
        .join(",");
      this.modal = {
        show: true,
        title: "",
        submitLoading: false,
      };
    },
    del(id) {
      this.$post(this.$api.PICTURE_CONFIG.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        title: "",
        url: "",
        type: "",
        imgType: "",
        isClick: "0",
        icon: "",
      };
      this.$refs.form.resetFields();
      this.imgCategory = [];
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.icon = this.form.icon
          .split(",")
          .map((item) => "image" + item.split("image")[1])
          .join(",");
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.PICTURE_CONFIG.EDIT
            : this.$api.PICTURE_CONFIG.INSERT,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getProjectType();
  },
};
</script>

<style lang="less" scoped>
.picture-config {
  width: 100%;
  height: 100%;
}
</style>